import styled from "@emotion/styled"
import { graphql } from "gatsby"
import React from "react"
import { Flex } from "theme-ui"
import ContentModules from "../components/content-modules"
import SEO from "../components/seo"

const Container = styled(Flex)`
  flex-direction: column;
  justify-content: ${(props) => (props.centerPage ? "center" : "flex-start")};
  flex: 1;
  width: 60%;
  margin: auto;
`

const PageTemplate = ({ data }) => {
  let { page } = data
  const { metaDescription, metaTitle } = page

  return (
    <Container centerPage={page.centerPage}>
      <SEO
        title={metaTitle || "Palmes Tennis Society"}
        description={
          metaDescription?.metaDescription || "Palmes Tennis Society"
        }
      />
      <ContentModules allModules={page.contentModules} />
    </Container>
  )
}

export default PageTemplate

export const pageQuery = graphql`
  query PageQuery($slug: String!) {
    page: contentfulPage(slug: { eq: $slug }) {
      ...PageContent
    }
  }
`
